:root {
  --font-hyperlegible: "Atkinson Hyperlegible", sans-serif;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  min-height: 100vh;
  font-size: 100%;
}

body {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);
  text-align: center;
}

#url-input-form {
  margin-top: var(--spacing);
  display: none;
}

#url-input {
  font-family: var(--font-hyperlegible);
}

#preview-url-wrapper {
  font-size: calc(var(--font-size) * 2);
  font-family: var(--font-hyperlegible);
}

#preview-card {
  max-width: 600px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

#preview-card header {
  padding: calc(var(--block-spacing-vertical)) calc(var(--block-spacing-horizontal) * 1.5);
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

#preview-card[aria-busy="true"] header {
  display: none;
}

#preview-card footer {
  padding: calc(var(--block-spacing-vertical)) calc(var(--block-spacing-horizontal) * 1.5);
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

#preview-card[aria-busy="true"] footer {
  display: none;
}

#preview-title {
  margin-bottom: 0;
  font-weight: normal;
}

#preview-image-wrapper {
  justify-content: center;
  align-items: center;
  display: none;
}

#preview-description {
  font-size: calc(var(--font-size) * 1.3);
  margin-bottom: 0;
}

/*# sourceMappingURL=index.cd2d0ae9.css.map */
